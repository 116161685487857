import React from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { isEmpty } from 'lodash-es'
import { getCurrentLang } from '../../utils/current-language-service'
import { getConfigByLanguage } from '../../utils/common-utils'
import { TabList, TabPanel, Tabs } from 'react-tabs'
import { Tab } from '../Tab/Tab'
import ShadowedContainer from '../ShadowedContainer/ShadowedContainer'
import Button from '../Button/Button'
import componentStyles from './MonitoringTabs.module.scss'
import LockIcon from '../../assets/images/lock-icon.svg'

import MonitoringAllListRu from '../../assets/images/ru/ru_Overview-Monitoring-all-list.svg'
import MonitoringCorrectionListRu from '../../assets/images/ru/ru_Overview-Monitoring-correction-list.svg'
import MonitoringFatalListRu from '../../assets/images/ru/ru_Overview-Monitoring-fatal-list.svg'

import MonitoringAllListEn from '../../assets/images/en/en_Overview-Monitoring-all-list.svg'
import MonitoringCorrectionListEn from '../../assets/images/en/en_Overview-Monitoring-correction-list.svg'
import MonitoringFatalListEn from '../../assets/images/en/en_Overview-Monitoring-fatal-list.svg'

const dateFormat = 'D.MM'
const datePeriodPlaceholder = /\[.*?]/g
const bracketsRegExp = /[\[\]]/g
const images = {
  en: {
    MonitoringAllList: MonitoringAllListEn,
    MonitoringCorrectionList: MonitoringCorrectionListEn,
    MonitoringFatalList: MonitoringFatalListEn,
  },
  ru: {
    MonitoringAllList: MonitoringAllListRu,
    MonitoringCorrectionList: MonitoringCorrectionListRu,
    MonitoringFatalList: MonitoringFatalListRu,
  },
}

class MonitoringTabs extends React.Component {
  constructor(props) {
    super(props)
    this.containerEl = {}
    this.onClick = this.onClick.bind(this)
    this.state = {
      selectedTabIndex: 0,
      selectedTabWasChanged: false,
      showTooltip: false,
    }
  }

  componentDidMount() {
    this.containerEl = document.querySelector(
      `.${componentStyles.tabContainer}`
    )
    window.addEventListener('click', this.onClick)
    this.prepareDates()
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onClick)
  }

  prepareDates() {
    const dateElements =
      this.containerEl &&
      this.containerEl.querySelectorAll(`[id*="daterange"] text`)
    if (!isEmpty(dateElements)) {
      dateElements.forEach(dateSpan => {
        let dateString = dateSpan && dateSpan.innerHTML
        const braces = dateString.match(datePeriodPlaceholder)
        braces.forEach(item => {
          const dateWithoutBrackets = item.replace(bracketsRegExp, '')
          const today = moment()
          let calculatedDate
          if (dateWithoutBrackets.includes('+')) {
            const [, diff] = dateWithoutBrackets.split('+')
            calculatedDate = today
              .add(parseInt(diff), 'days')
              .format(dateFormat)
          } else {
            const [, diff] = dateWithoutBrackets.split('−')
            calculatedDate = today
              .subtract(parseInt(diff), 'days')
              .format(dateFormat)
          }
          dateString = dateString.replace(item, calculatedDate)
        })
        dateSpan.innerHTML = dateString
      })
    }
  }

  onTabSelect(currentIndex) {
    this.props.tabSelectHandler(currentIndex)
    this.setState({ selectedTabIndex: currentIndex })
    if (!this.state.selectedTabWasChanged) {
      this.setState({ selectedTabWasChanged: true })
    }
    window.requestAnimationFrame(this.checkTabs.bind(this))
  }

  checkTabs() {
    const selected = document.querySelectorAll(
      `.${componentStyles.tabContent}`
    )[this.state.selectedTabIndex]
    if (
      !(
        selected &&
        selected.classList.contains(componentStyles.tabContentSelected)
      )
    ) {
      window.requestAnimationFrame(this.checkTabs.bind(this))
    } else {
      this.prepareDates()
    }
  }

  onClick(event) {
    const { target } = event
    if (this.state.showTooltip) {
      if (target && !target.closest(`.${componentStyles.endingTooltip}`)) {
        this.toggleTooltip(false)
      }
    } else {
      if (target && target.closest(`.${componentStyles.endingDays}`)) {
        this.toggleTooltip(true)
      }
    }
  }

  toggleTooltip(newValue) {
    this.setState({ showTooltip: newValue })
  }

  toggleContacts() {
    this.toggleTooltip(false)
  }

  render() {
    const language = getCurrentLang()
    const config = getConfigByLanguage(language)
    const { beforeEndTitle, days, states, tooltip } = config.index.monitoring
    const {
      MonitoringAllList,
      MonitoringCorrectionList,
      MonitoringFatalList,
    } = images[language]
    const { children } = this.props
    const shadowedTitleClass = classNames(
      componentStyles.shadowedTitle,
      'content-basic-title'
    )
    const monitoringTableClasses = classNames(
      componentStyles.statImg,
      componentStyles.monitoringTable
    )
    const tooltipClasses = classNames(componentStyles.endingTooltip, {
      [componentStyles.endingTooltipActive]: this.state.showTooltip,
    })

    return (
      <Tabs
        className={componentStyles.tabContainer}
        selectedIndex={this.state.selectedTabIndex}
        onSelect={this.onTabSelect.bind(this)}
        selectedTabClassName={componentStyles.tabSelected}
        selectedTabPanelClassName={componentStyles.tabContentSelected}
      >
        <div className={componentStyles.tabRow}>
          <div className={componentStyles.endingBlock}>
            <h4 className={shadowedTitleClass}>{beforeEndTitle}</h4>
            <div className={componentStyles.endingDays}>
              <span>{days}</span>
            </div>
            <ShadowedContainer className={tooltipClasses}>
              <LockIcon className={componentStyles.endingLock} />
              <p className={componentStyles.endingText}>{tooltip[0]}</p>
              <p className={componentStyles.endingText}>{tooltip[1]}</p>
              <Button
                href="#contact"
                className={componentStyles.endingLink}
                offset={'100'}
                onClick={this.toggleContacts.bind(this)}
              >
                {tooltip[2]}
              </Button>
            </ShadowedContainer>
          </div>
          <div className={componentStyles.stateBlock}>
            <h4 className={shadowedTitleClass}>{states.title}</h4>
            <TabList className={componentStyles.tabList}>
              {states.list.map(({ text }, index) => {
                const classes = {
                  roundClass: classNames({
                    [componentStyles.round]:
                      !this.state.selectedTabWasChanged &&
                      index !== this.state.selectedTabIndex,
                  }),
                  tabClass: componentStyles.tab,
                }
                return (
                  <Tab
                    customClasses={classes}
                    key={`item-${index}`}
                    text={text}
                    size={'small'}
                  />
                )
              })}
            </TabList>
          </div>
        </div>
        <TabPanel className={componentStyles.tabContent}>
          <MonitoringAllList className={monitoringTableClasses} />
          {children}
        </TabPanel>
        <TabPanel className={componentStyles.tabContent}>
          <MonitoringCorrectionList className={monitoringTableClasses} />
          {children}
        </TabPanel>
        <TabPanel className={componentStyles.tabContent}>
          <MonitoringFatalList className={monitoringTableClasses} />
          {children}
        </TabPanel>
      </Tabs>
    )
  }
}

export default MonitoringTabs
