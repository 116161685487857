import React, { useState } from 'react'
import classNames from 'classnames'
import { getCurrentLang } from '../../../utils/current-language-service'
import { getConfigByLanguage } from '../../../utils/common-utils'
import IndexBlockTitle from '../../../components/IndexBlockTitle/IndexBlockTitle'
import TextBlockList from '../../../components/TextBlockList/TextBlockList'
import Button from '../../../components/Button/Button'
import ShadowedContainer from '../../../components/ShadowedContainer/ShadowedContainer'
import MonitoringTabs from '../../../components/MonitoringTabs/MonitoringTabs'
import layoutStyles from '../../../components/Layout/Layout.module.scss'
import componentStyles from './IndexMonitoring.module.scss'
import TotalArrow1280 from '../../../assets/images/total-arr-1280.svg'
import DetailArrow1280 from '../../../assets/images/detail-arr-1280.svg'
import CloseIcon from '../../../assets/images/cross-icon.svg'

import MonitoringAllDetailsEn from '../../../assets/images/en/en_Overview-Monitoring-all-highlight-popup.svg'
import MonitoringAllHighlightEn from '../../../assets/images/en/en_Overview-Monitoring-all-highlight.svg'
import MonitoringCorrectionDetailsEn from '../../../assets/images/en/en_Overview-Monitoring-correction-highlight-popup.svg'
import MonitoringCorrectionHighlightEn from '../../../assets/images/en/en_Overview-Monitoring-correction-highlight.svg'
import MonitoringFatalDetailsEn from '../../../assets/images/en/en_Overview-Monitoring-fatal-highlight-popup.svg'
import MonitoringFatalHighlightEn from '../../../assets/images/en/en_Overview-Monitoring-fatal-highlight.svg'

import MonitoringAllDetailsRu from '../../../assets/images/ru/ru_Overview-Monitoring-all-highlight-popup.svg'
import MonitoringAllHighlightRu from '../../../assets/images/ru/ru_Overview-Monitoring-all-highlight.svg'
import MonitoringCorrectionDetailsRu from '../../../assets/images/ru/ru_Overview-Monitoring-correction-highlight-popup.svg'
import MonitoringCorrectionHighlightRu from '../../../assets/images/ru/ru_Overview-Monitoring-correction-highlight.svg'
import MonitoringFatalDetailsRu from '../../../assets/images/ru/ru_Overview-Monitoring-fatal-highlight-popup.svg'
import MonitoringFatalHighlightRu from '../../../assets/images/ru/ru_Overview-Monitoring-fatal-highlight.svg'

const images = {
  en: {
    MonitoringAllDetails: MonitoringAllDetailsEn,
    MonitoringAllHighlight: MonitoringAllHighlightEn,
    MonitoringCorrectionDetails: MonitoringCorrectionDetailsEn,
    MonitoringCorrectionHighlight: MonitoringCorrectionHighlightEn,
    MonitoringFatalDetails: MonitoringFatalDetailsEn,
    MonitoringFatalHighlight: MonitoringFatalHighlightEn,
  },
  ru: {
    MonitoringAllDetails: MonitoringAllDetailsRu,
    MonitoringAllHighlight: MonitoringAllHighlightRu,
    MonitoringCorrectionDetails: MonitoringCorrectionDetailsRu,
    MonitoringCorrectionHighlight: MonitoringCorrectionHighlightRu,
    MonitoringFatalDetails: MonitoringFatalDetailsRu,
    MonitoringFatalHighlight: MonitoringFatalHighlightRu,
  },
}

const IndexMonitoring = () => {
  const [currentIndex, currentIndexSetter] = useState(0)
  const [isModalVisible, modalVisibilitySetter] = useState(false)

  const tabSelectHandler = currentIndex => {
    currentIndexSetter(currentIndex)
  }

  const toggleModal = () => {
    modalVisibilitySetter(!isModalVisible)
  }

  const language = getCurrentLang()
  const config = getConfigByLanguage(language)
  const {
    advantages,
    annotations,
    id,
    subtitle,
    title,
  } = config.index.monitoring
  const {
    MonitoringAllDetails,
    MonitoringAllHighlight,
    MonitoringCorrectionDetails,
    MonitoringCorrectionHighlight,
    MonitoringFatalDetails,
    MonitoringFatalHighlight,
  } = images[language]

  const annotationsDetailTextClasses = classNames(
    componentStyles.annotationsDetailText,
    componentStyles.annotationsText
  )
  const annotationsTotalTextClasses = classNames(
    componentStyles.annotationsTotalText,
    componentStyles.annotationsText
  )
  const monitoringExtraClasses = classNames(
    componentStyles.monitoringExtra,
    componentStyles.customShadow
  )
  const shadowedDetailedClasses = classNames(
    componentStyles.shadowedDetailed,
    componentStyles.customShadow,
    {
      [componentStyles.shadowedDetailedHidden]: !isModalVisible,
    }
  )

  let detail
  switch (currentIndex) {
    case 1:
      detail = (
        <MonitoringCorrectionDetails className={componentStyles.statImg} />
      )
      break
    case 2:
      detail = <MonitoringFatalDetails className={componentStyles.statImg} />
      break
    default:
      detail = <MonitoringAllDetails className={componentStyles.statImg} />
      break
  }
  let highlight
  switch (currentIndex) {
    case 1:
      highlight = (
        <ShadowedContainer className={monitoringExtraClasses}>
          <MonitoringCorrectionHighlight className={componentStyles.statImg} />
          <button
            className={componentStyles.showModalButton}
            onClick={toggleModal}
          >
            Show modal
          </button>
        </ShadowedContainer>
      )
      break
    case 2:
      highlight = (
        <ShadowedContainer className={monitoringExtraClasses}>
          <MonitoringFatalHighlight className={componentStyles.statImg} />
          <button
            className={componentStyles.showModalButton}
            onClick={toggleModal}
          >
            Show modal
          </button>
        </ShadowedContainer>
      )
      break
    default:
      highlight = (
        <ShadowedContainer className={monitoringExtraClasses}>
          <MonitoringAllHighlight className={componentStyles.statImg} />
          <button
            className={componentStyles.showModalButton}
            onClick={toggleModal}
          >
            Show modal
          </button>
        </ShadowedContainer>
      )
      break
  }

  return (
    <div className={componentStyles.monitoring} id={id}>
      <div className={layoutStyles.container}>
        <div className={layoutStyles.indexBlockWrapper}>
          <IndexBlockTitle title={title} subtitle={subtitle} />
          <div className={componentStyles.content}>
            <div className={componentStyles.advantages}>
              <TextBlockList
                className={componentStyles.advantagesList}
                listItemClass={componentStyles.advantagesListItem}
                list={advantages.list}
                classModifier={'monitoring'}
              />
              <Button
                className={componentStyles.advantagesBtn}
                color={'orange'}
                href={advantages.url}
              >
                {advantages.buttonText}
              </Button>
            </div>
            <ShadowedContainer className={componentStyles.shadowed}>
              <MonitoringTabs tabSelectHandler={tabSelectHandler}>
                {highlight}
              </MonitoringTabs>
              <div className={componentStyles.annotationsTotal}>
                <TotalArrow1280
                  className={componentStyles.annotationsTotalArrow1280}
                />
                <p className={annotationsTotalTextClasses}>
                  {annotations.total}
                </p>
              </div>
              <div className={componentStyles.annotationsDetail}>
                <DetailArrow1280
                  className={componentStyles.annotationsDetailArrow1280}
                />
                <p className={annotationsDetailTextClasses}>
                  {annotations.detail}
                </p>
              </div>
            </ShadowedContainer>
            <ShadowedContainer className={shadowedDetailedClasses}>
              <button
                className={componentStyles.shadowedDetailedCloseButton}
                onClick={toggleModal}
              >
                <CloseIcon />
              </button>
              <p className={componentStyles.shadowedDetailedAnnotation}>
                {annotations.detail}
              </p>
              {detail}
            </ShadowedContainer>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IndexMonitoring
