import React, { useState } from 'react'
import { getCurrentLang } from '../../../utils/current-language-service'
import { getConfigByLanguage } from '../../../utils/common-utils'
import { TabList, TabPanel, Tabs } from 'react-tabs'
import { Tab } from '../../../components/Tab/Tab'
import IndexBlockTitle from '../../../components/IndexBlockTitle/IndexBlockTitle'
import TextBlockList from '../../../components/TextBlockList/TextBlockList'
import Button from '../../../components/Button/Button'
import ShadowedContainer from '../../../components/ShadowedContainer/ShadowedContainer'
import layoutStyles from '../../../components/Layout/Layout.module.scss'
import componentStyles from './IndexForecasting.module.scss'

import ForecastAllFeaturesEn from '../../../assets/images/en/en_Overview-Forecasting-AllFeatures.svg'
import ForecastTargeting1UsersEn from '../../../assets/images/en/en_Overview-Forecasting-targeting1-users.svg'
import ForecastTargeting1ViewsEn from '../../../assets/images/en/en_Overview-Forecasting-targeting1-views.svg'
import ForecastTargeting2UsersEn from '../../../assets/images/en/en_Overview-Forecasting-targeting2-users.svg'
import ForecastTargeting2ViewsEn from '../../../assets/images/en/en_Overview-Forecasting-targeting2-views.svg'

import ForecastAllFeaturesRu from '../../../assets/images/ru/ru_Overview-Forecasting-AllFeatures.svg'
import ForecastTargeting1UsersRu from '../../../assets/images/ru/ru_Overview-Forecasting-targeting1-users.svg'
import ForecastTargeting1ViewsRu from '../../../assets/images/ru/ru_Overview-Forecasting-targeting1-views.svg'
import ForecastTargeting2UsersRu from '../../../assets/images/ru/ru_Overview-Forecasting-targeting2-users.svg'
import ForecastTargeting2ViewsRu from '../../../assets/images/ru/ru_Overview-Forecasting-targeting2-views.svg'
import classNames from 'classnames'

const images = {
  en: {
    ForecastAllFeatures: ForecastAllFeaturesEn,
    ForecastTargeting1Users: ForecastTargeting1UsersEn,
    ForecastTargeting1Views: ForecastTargeting1ViewsEn,
    ForecastTargeting2Users: ForecastTargeting2UsersEn,
    ForecastTargeting2Views: ForecastTargeting2ViewsEn,
  },
  ru: {
    ForecastAllFeatures: ForecastAllFeaturesRu,
    ForecastTargeting1Users: ForecastTargeting1UsersRu,
    ForecastTargeting1Views: ForecastTargeting1ViewsRu,
    ForecastTargeting2Users: ForecastTargeting2UsersRu,
    ForecastTargeting2Views: ForecastTargeting2ViewsRu,
  },
}

const IndexForecasting = () => {
  const language = getCurrentLang()
  const config = getConfigByLanguage(language)
  const {
    advantages,
    examples,
    id,
    examplesChildren,
    subtitle,
    title,
  } = config.index.forecasting
  const {
    ForecastAllFeatures,
    ForecastTargeting1Users,
    ForecastTargeting1Views,
    ForecastTargeting2Users,
    ForecastTargeting2Views,
  } = images[language]
  const [selectedTabWasChanged, selectedTabWasChangedToggler] = useState(false)
  const [selectedTabIndex, selectedTabIndexSetter] = useState(0)

  const onTabSelect = currentIndex => {
    selectedTabIndexSetter(currentIndex)
    if (!selectedTabWasChanged) {
      selectedTabWasChangedToggler(true)
    }
  }

  return (
    <div className={componentStyles.forecasting} id={id}>
      <div className={layoutStyles.container}>
        <div className={layoutStyles.indexBlockWrapper}>
          <IndexBlockTitle title={title} subtitle={subtitle} />
          <div className={componentStyles.content}>
            <ShadowedContainer className={componentStyles.shadowed}>
              <Tabs
                className={componentStyles.tabContainer}
                onSelect={onTabSelect}
                selectedTabClassName={componentStyles.tabSelected}
                selectedTabPanelClassName={componentStyles.tabContentSelected}
              >
                <div className={componentStyles.tabRow}>
                  <TabList className={componentStyles.tabList}>
                    {examples.map(({ overlay, title, text }, index) => {
                      const classes = {
                        roundClass: classNames(
                          componentStyles.tabRound,
                          componentStyles[`tabRound${index}`],
                          {
                            [componentStyles.roundAnimation]:
                              !selectedTabWasChanged &&
                              index !== selectedTabIndex,
                          }
                        ),
                        tabClass: componentStyles.tab,
                        tabTextClass: componentStyles.tabText,
                        tabTitleClass: componentStyles.tabTitle,
                      }
                      return (
                        <Tab
                          customClasses={classes}
                          key={`item-${index}`}
                          overlay={overlay}
                          title={title}
                          text={text}
                        />
                      )
                    })}
                  </TabList>
                </div>
                <TabPanel className={componentStyles.tabContent}>
                  <div className={componentStyles.tabDescription}>
                    <h4 className={componentStyles.tabDescriptionTitle}>
                      {examples[0].title}
                    </h4>
                    <p className={componentStyles.tabDescriptionText}>
                      {examples[0].text}
                    </p>
                  </div>
                  <Tabs>
                    <div className={componentStyles.tabChildrenRow}>
                      <h4 className={componentStyles.tabChildrenTitle}>
                        {examplesChildren.targeting1.title}
                      </h4>
                      <TabList className={componentStyles.tabChildrenList}>
                        {examplesChildren.targeting1.list.map(
                          ({ text }, index) => {
                            const classes = {
                              roundClass: componentStyles.tabChildrenRound,
                              tabClass: componentStyles.tabChildren,
                            }
                            return (
                              <Tab
                                customClasses={classes}
                                key={`item-${index}`}
                                text={text}
                                tabStyle={'link'}
                              />
                            )
                          }
                        )}
                      </TabList>
                    </div>
                    <TabPanel className={componentStyles.tabChildrenContent}>
                      <ForecastTargeting1Users
                        className={componentStyles.statImg}
                      />
                    </TabPanel>
                    <TabPanel className={componentStyles.tabChildrenContent}>
                      <ForecastTargeting1Views
                        className={componentStyles.statImg}
                      />
                    </TabPanel>
                  </Tabs>
                </TabPanel>
                <TabPanel className={componentStyles.tabContent}>
                  <div className={componentStyles.tabDescription}>
                    <h4 className={componentStyles.tabDescriptionTitle}>
                      {examples[1].title}
                    </h4>
                    <p className={componentStyles.tabDescriptionText}>
                      {examples[1].text}
                    </p>
                  </div>
                  <Tabs>
                    <div className={componentStyles.tabChildrenRow}>
                      <h4 className={componentStyles.tabChildrenTitle}>
                        {examplesChildren.targeting2.title}
                      </h4>
                      <TabList className={componentStyles.tabChildrenList}>
                        {examplesChildren.targeting2.list.map(
                          ({ text }, index) => {
                            const classes = {
                              roundClass: componentStyles.tabChildrenRound,
                              tabClass: componentStyles.tabChildren,
                            }
                            return (
                              <Tab
                                customClasses={classes}
                                key={`item-${index}`}
                                text={text}
                                tabStyle={'link'}
                              />
                            )
                          }
                        )}
                      </TabList>
                    </div>
                    <TabPanel className={componentStyles.tabChildrenContent}>
                      <ForecastTargeting2Users
                        className={componentStyles.statImg}
                      />
                    </TabPanel>
                    <TabPanel className={componentStyles.tabChildrenContent}>
                      <ForecastTargeting2Views
                        className={componentStyles.statImg}
                      />
                    </TabPanel>
                  </Tabs>
                </TabPanel>
                <TabPanel className={componentStyles.tabContent}>
                  <div className={componentStyles.tabDescription}>
                    <h4 className={componentStyles.tabDescriptionTitle}>
                      {examples[2].title}
                    </h4>
                    <p className={componentStyles.tabDescriptionText}>
                      {examples[2].text}
                    </p>
                  </div>
                  <ForecastAllFeatures className={componentStyles.statImg} />
                </TabPanel>
              </Tabs>
            </ShadowedContainer>
            <div className={componentStyles.advantages}>
              <TextBlockList
                className={componentStyles.advantagesList}
                listItemClass={componentStyles.advantagesListItem}
                list={advantages.list}
                classModifier={'forecasting'}
              />
              <Button color={'orange'} href={advantages.url}>
                {advantages.buttonText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IndexForecasting
