import React from 'react'
import classNames from 'classnames'
import { getConfigByLanguage, pascalCase } from '../../utils/common-utils'
import { getCurrentLang } from '../../utils/current-language-service'
import TextBlockList from '../TextBlockList/TextBlockList'
import Button from '../Button/Button'
import componentStyles from './ProductList.module.scss'
import ForecastingIcon from '../../assets/images/Forecasting-icon.svg'
import MonitoringIcon from '../../assets/images/Monitoring-icon.svg'
import YieldManagementIcon from '../../assets/images/Yield-Management-icon.svg'

const iconsList = {
  ForecastingIcon,
  MonitoringIcon,
  YieldManagementIcon
}

const ProductList = ({ customClasses }) => {
  const language = getCurrentLang()
  const config = getConfigByLanguage(language)
  const { list } = config.index.products

  const productTitleClasses = classNames(
    componentStyles.productTitle,
    'content-basic-title')
  const productDescriptionTextClasses = classNames(
    componentStyles.productDescriptionText)
  const productListClasses = classNames(
    componentStyles.productList,
    customClasses && customClasses.productListClass)

  const itemsList = list.map(({ buttonText, iconComponent, text, title, url }, index) => {
    const Component = iconsList[pascalCase(iconComponent)]

    return (
      <div className={componentStyles.product} key={`item-${index}`}>
        <div className={componentStyles.productTitleBlock}>
          <Component className={componentStyles.productTitleIcon}/>
          <h4 className={productTitleClasses}>{title}</h4>
        </div>
        <TextBlockList className={componentStyles.productDescription} listItemClass={productDescriptionTextClasses}
                       list={[{ text }]} classModifier={'product'}/>
        <Button className={componentStyles.productButton} href={url}>{buttonText}</Button>
      </div>
    )
  })

  return (
    <div className={productListClasses}>
      {itemsList}
    </div>
  )
}

export default ProductList
