import React, { useState } from 'react'
import classNames from 'classnames'
import { getCurrentLang } from '../../../utils/current-language-service'
import { getConfigByLanguage } from '../../../utils/common-utils'
import ProductList from '../../../components/ProductList/ProductList'
import layoutStyles from '../../../components/Layout/Layout.module.scss'
import componentStyles from './IndexProducts.module.scss'
import videoPoster from "../../../../static/video-poster.jpg"

const IndexProducts = () => {
  const language = getCurrentLang()
  const config = getConfigByLanguage(language)
  const { products, video } = config.index
  const indexBlockWrapperClasses = classNames(
    layoutStyles.indexBlockWrapper,
    componentStyles.blockWrapper
  )
  const videoStyles = classNames(
    componentStyles.video,
  )
  const videoBlockStyles = classNames(
    componentStyles.videoBlock
  )
  const videoBlockEmptyContainerStyles = classNames(
    componentStyles.videoBlockEmptyContainer
  )
  const videoBlockPosterStyles = classNames(
    componentStyles.videoBlockPoster,
  )
  const videoFrameStyles = classNames(
    componentStyles.videoFrame
  )
  const hiddenStyles = classNames(
    componentStyles.hidden
  )

  const [isActive, setActive] = useState("false");

  const handleToggle = () => {
    setActive(!isActive);
  };

  return (
    <div className={componentStyles.forecasting} id={products.id}>
      <div className={layoutStyles.container}>
        <div className={videoStyles}>
          <h2>{video.title}</h2>
          <div className={videoBlockStyles}>
            <div className={videoBlockEmptyContainerStyles}></div>
            <p className={isActive ? videoBlockPosterStyles : hiddenStyles}>
              <img src={videoPoster} onClick={handleToggle} />
            </p>
            <iframe
              className={videoFrameStyles}
              src="https://www.youtube.com/embed/arck1RXG5qA?autoplay=1"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen />
          </div>
        </div>
        <div className={indexBlockWrapperClasses}>
          <ProductList />
        </div>
      </div>
    </div>
  )
}

export default IndexProducts
