import React from 'react'
import classNames from 'classnames'
import componentStyles from './TextBlockItem.module.scss'

const TextBlockItem = ({ className, title, text }) => {
  const containerClass = classNames(
    className,
    componentStyles.listItem)
  const titleClass = classNames(
    componentStyles.title,
    'content-basic-title')
  const textClass = classNames(
    componentStyles.text,
    'content-basic-paragraph')

  const titleElement = title && title.length > 0 ? (<h4 className={titleClass}>{title}</h4>) : null
  const textElement = text && text.length > 0 ? (<p className={textClass}>{text}</p>) : null

  return (
    <div className={containerClass}>
      {titleElement}
      {textElement}
    </div>
  )
}

export default TextBlockItem

