import React from 'react'
import IndexPage from '../parts/index/IndexPage/IndexPage'
import { graphql, StaticQuery } from 'gatsby'

const enQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fields: { language: { eq: "en" }, source: { eq: "posts" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 4
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date
            title
            theme
            thumbnail {
              childImageSharp {
                fixed(
                  width: 500
                  height: 300
                  quality: 100
                  cropFocus: CENTER
                ) {
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

const IndexEn = ({ location }) => (
  <StaticQuery
    query={enQuery}
    render={postsData => (
      <IndexPage location={location} language={'en'} postsData={postsData} />
    )}
  />
)

export default IndexEn
