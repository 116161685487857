import React, { useEffect } from 'react'
import * as moment from 'moment'
import { getCurrentLang } from '../../../utils/current-language-service'
import { getConfigByLanguage } from '../../../utils/common-utils'
import IndexBlockTitle from '../../../components/IndexBlockTitle/IndexBlockTitle'
import TextBlockList from '../../../components/TextBlockList/TextBlockList'
import Button from '../../../components/Button/Button'
import ShadowedContainer from '../../../components/ShadowedContainer/ShadowedContainer'
import layoutStyles from '../../../components/Layout/Layout.module.scss'
import componentStyles from './IndexYieldManagement.module.scss'

import YieldEn from '../../../assets/images/en/en_Overview-YieldManagement.svg'
import YieldRu from '../../../assets/images/ru/ru_Overview-YieldManagement.svg'

const dateFormat = 'MMMM'
const currentMonthPlaceholder = /&lt;.*?&gt;/g
const images = {
  en: {
    Yield: YieldEn
  },
  ru: {
    Yield: YieldRu
  }
}


const IndexYieldManagement = () => {
  const language = getCurrentLang()
  const config = getConfigByLanguage(language)
  const { advantages, id, subtitle, title } = config.index.yieldManagement
  const { Yield } = images[language]

  useEffect(() => {
    const statImgElement = document.querySelector(`.${componentStyles.stat}`)
    const currentStatMonthElement = statImgElement && statImgElement.querySelector(`[id*="currentmonth"] text`)
    const formattedMonth = language === 'ru' ? moment().format(dateFormat).toLowerCase() : moment().format(dateFormat)
    if (currentStatMonthElement) {

      currentStatMonthElement.innerHTML = currentStatMonthElement.innerHTML.replace(currentMonthPlaceholder ,formattedMonth)
    }
  }, [])

  return(
    <div className={componentStyles.monitoring} id={id}>
      <div className={layoutStyles.container}>
        <div className={layoutStyles.indexBlockWrapper}>
          <IndexBlockTitle title={title} subtitle={subtitle}/>
          <div className={componentStyles.content}>
            <div className={componentStyles.advantages}>
              <TextBlockList className={componentStyles.advantagesList} listItemClass={componentStyles.advantagesListItem} list={advantages.list} classModifier={'yieldManagement'}/>
              <Button className={componentStyles.advantagesBtn} color={'orange'} href={advantages.url}>{advantages.buttonText}</Button>
            </div>
            <ShadowedContainer className={componentStyles.shadowed}>
              <Yield className={componentStyles.stat} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"/>
            </ShadowedContainer>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IndexYieldManagement
