import React from 'react'
import classNames from 'classnames'
import { Tab as ReactTab } from 'react-tabs'
import componentStyles from './Tab.module.scss'
import { camelCase } from 'lodash-es'

const Tab = ({
  children,
  customClasses,
  overlay,
  size,
  tabStyle,
  text,
  title,
  ...other
}) => {
  const isLinkStyle = tabStyle === 'link'

  const classes = classNames(
    {
      [componentStyles.tab]: !isLinkStyle,
      [componentStyles[camelCase(`tab size ${size}`)]]: size,
      [componentStyles.tabLink]: isLinkStyle,
    },
    customClasses && customClasses.tabClass
  )
  const selectedClasses = classNames(
    {
      [componentStyles.tabSelected]: !isLinkStyle,
      [componentStyles.tabLinkSelected]: isLinkStyle,
    },
    customClasses && customClasses.selectedTabClass
  )
  const tabTextClasses = classNames(
    componentStyles.tabText,
    customClasses && customClasses.tabTextClass
  )
  const tabTitleClasses = classNames(
    componentStyles.tabTitle,
    customClasses && customClasses.tabTitleClass
  )
  const tabTitleRowClasses = classNames(
    componentStyles.tabTitleRow,
    customClasses && customClasses.tabTitleRowClass
  )
  const roundClasses = classNames(
    componentStyles.round,
    customClasses && customClasses.roundClass
  )

  const titleElement = title ? (
    <h4 className={tabTitleClasses}>{title}</h4>
  ) : null
  const roundElement = isLinkStyle ? null : <div className={roundClasses} />
  const overlayElement = overlay ? (
    <span className={componentStyles.overlayRow} />
  ) : null
  const titleRowElement =
    title || !isLinkStyle ? (
      <div className={tabTitleRowClasses}>
        {roundElement}
        {titleElement}
      </div>
    ) : null

  return (
    <ReactTab
      className={classes}
      selectedClassName={selectedClasses}
      {...other}
    >
      {titleRowElement}
      <p className={tabTextClasses}>{text}</p>
      {children}
      {overlayElement}
    </ReactTab>
  )
}

Tab.tabsRole = 'Tab'

export { Tab }
