import React from 'react'
import classNames from 'classnames'
import { camelCase } from 'lodash-es'
import TextBlockItem from '../TextBlockItem/TextBlockItem'
import componentStyles from './TextBlockList.module.scss'

const TextBlockList = ({ classModifier, className, list, listItemClass }) => {
  const modifiedListClass = camelCase(`list ${classModifier}`)
  const containerClass = classNames(
    className,
    componentStyles.list,
    componentStyles[modifiedListClass])

  return (
    <div className={containerClass}>
      {list.map(({ title, text }, index) => (
        <TextBlockItem className={listItemClass} key={`item-${index}`} title={title} text={text}/>
      ))}
    </div>
  )
}

export default TextBlockList

