import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Layout from '../../../components/Layout/Layout'
import IndexProducts from '../IndexProducts/IndexProducts'
import IndexForecasting from '../IndexForecasting/IndexForecasting'
import IndexMonitoring from '../IndexMonitoring/IndexMonitoring'
import IndexYieldManagement from '../IndexYieldManagement/IndexYieldManagement'
import IndexPartners from '../IndexPartners/IndexPartners'
import { getCurrentLangKey } from 'ptz-i18n'
import { getConfigByLanguage } from '../../../utils/common-utils'

const IndexPage = ({ language, location, seo, postsData }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              languages {
                defaultLangKey
                langs
              }
            }
          }
        }
      `}
      render={data => {
        const { langs, defaultLangKey } = data.site.siteMetadata.languages
        const url = location.pathname
        const currentLang =
          language || getCurrentLangKey(langs, defaultLangKey, url)
        const config = getConfigByLanguage(currentLang)
        const { seo } = config.index

        return (
          <Layout
            location={location}
            title={'Главная'}
            seo={seo}
            postsData={postsData}
          >
            <IndexProducts />
            <IndexForecasting />
            <IndexMonitoring />
            <IndexYieldManagement />
            {/* <IndexPartners /> */}
          </Layout>
        )
      }}
    />
  )
}

export default IndexPage
